<script setup>
// eslint-disable-next-line vue/prop-name-casing
import { useModal } from 'vue-final-modal';
import LoadTemplatePopup from '~/forms/components/form-builder/form-builder-load-template-popup.vue';
import SaveTemplatePopup from '~/forms/components/form-builder/form-builder-save-template-popup.vue';
import FormBuilderFields from '~/forms/components/form-builder/form-builder-fields.vue';
import FormBuilderNewSectionMenu from '~/forms/components/form-builder/form-builder-new-section-menu.vue';
import FormBuiderTableColumnConfigPopup from '~/forms/components/form-builder/form-builder-table-column-config-popup.vue';
import FormBuilderTableFieldPopup from '~/forms/components/form-builder/form-builder-table-field-popup.vue';
import TrashIcon from '~icons/hawk/trash-three';
import CopyIcon from '~icons/hawk/copy-three';
import { useFieldCondition } from '~/forms/composables/form-builder-field-schema.composable.js';
import FormBuilderDuplicateSectionOrFieldPopup from '~/forms/components/form-builder/form-builder-duplicate-section-or-field-popup.vue';

const props = defineProps(['selected_field', 'selected_section','draggable']);

const emits = defineEmits(['select_field', 'select_section']);



const form = ref({});
const form$ = ref(null);
const is_deleting = ref(null);
const is_duplicating = ref(null);
const counter = ref(0);
const $services = inject('$services');
const $toast = inject('$toast');
const $t = inject('$t');
const form_template_detail_store = inject('form_template_detail_store');

const { get_chained_fields, get_chain_condition_msg } = useFieldCondition();

const active_sections = computed(() => form_template_detail_store.sections.filter(section => section.status === 'active' && (+section.step_index === +form_template_detail_store.step_number || form_template_detail_store.step_number === -1)));
const active_sections_with_uid = computed(() => active_sections.value.filter(section => section.uid));
function loadTemplate(section) {
  const { open: openLoadTemplatePopup, close: closeLoadTemplatePopup } = useModal({
    component: LoadTemplatePopup,
    attrs: {
      section,
      form_template_detail_store,
      onClose() {
        closeLoadTemplatePopup();
      },
    },
  });
  return openLoadTemplatePopup();
}

function saveTemplate(section) {
  const { open: openSaveTemplatePopup, close: closeSaveTemplatePopup } = useModal({
    component: SaveTemplatePopup,
    attrs: {
      section,
      form_template_detail_store,
      onClose() {
        closeSaveTemplatePopup();
      },
    },
  });
  return openSaveTemplatePopup();
}

async function sectionMoved(data) {
  const sections = form_template_detail_store.form_template_detail.sections;
  try {
    const moved_section = sections[data.moved.newIndex];
    if (data.moved.newIndex - 1 >= 0)
      moved_section.previous_section = sections[
        data.moved.newIndex - 1
      ].uid;
    else
      moved_section.previous_section = null;
    if (moved_section.uid) {
      const response = await $services.forms.patch({
        attribute: `sections/${moved_section.uid}`,
        body: { section: moved_section },
      });
      sections[data.moved.newIndex] = { ...sections[data.moved.newIndex], ...response.data.section };
    }
  }
  catch (e) {
    $toast({ text: e?.data?.message || 'Section Updating failed!', type: 'error' });
    [sections[data.moved.newIndex], sections[data.moved.oldIndex]] = [sections[data.moved.oldIndex], sections[data.moved.newIndex]];
    form_template_detail_store.reset_form_template();
  }
}

async function duplicateSection(section) {
  const { open: openDuplicateSectionPopup, close: closeDuplicateSectionPopup } = useModal({
    component: FormBuilderDuplicateSectionOrFieldPopup,
    attrs: {
      header: $t('Copy section'),
      type: 'section',
      onClose() {
        closeDuplicateSectionPopup();
      },
      async onSave(data) {
        try {
          const obj = {
            name: data.name,
            uid: section.uid,
          };
          is_duplicating.value = true;
          let response = await $services.forms.post({
            attribute: 'sections/template/save',
            body: {
              section: obj,
            },
          });

          response = await $services.forms.post({
            attribute: 'sections/template/load',
            body: {
              section: {
                name: response.data.section.name,
                uid: response.data.section.uid,
                form: form_template_detail_store.form_template_detail.uid,
                previousSection: section.uid,
              },
            },
          });
          form_template_detail_store.form_template_detail.sections.push(response.data.section);
          form_template_detail_store.reset_form_template();
          is_duplicating.value = false;
        }
        catch (e) {
          $toast({ text: e?.data?.message || 'Section duplicating failed!', type: 'error' });
          is_duplicating.value = false;
        }
      },
    },
  });
  openDuplicateSectionPopup();
}

async function deleteSection(section) {
  const chained_fields = get_chained_fields(section.element.fields.map(field => field.uid));

  if (chained_fields.length) {
    const error_message = get_chain_condition_msg(form_template_detail_store.form_template_detail.workflow, chained_fields, 'section');
    $toast({ text: error_message, type: 'error', timeout: 5000 });
    return;
  }

  try {
    is_deleting.value = true;
    await form_template_detail_store.delete_section(section.element.uid, { section_index: section.index });
    emits('select_section', null);
    is_deleting.value = false;
  }
  catch (e) {
    $toast({ text: e?.data?.message || 'Section deleting failed!', type: 'error' });
    is_deleting.value = false;
  }
}

function selectSection(section, event) {
  emits('select_section', { section: section.element, section_index: section.index, focus_description: event.srcElement.id === 'section_description', counter: counter.value++ });
}

const table_column_config_popup = useModal({
  component: FormBuiderTableColumnConfigPopup,
});

const form_builder_table_field_popup = useModal({
  component: FormBuilderTableFieldPopup,
});

function openEditTableColumnPopup(table_section_data, selected_column) {
  form_builder_table_field_popup.patchOptions({
    attrs: {
      fields: table_section_data.element.fields,
      section: table_section_data.element,
      selected_column,
      onClose() {
        form_builder_table_field_popup.close();
      },
      onDelete(selected_field) {
        const index = table_section_data.element.fields.findIndex(field => field.uid === selected_field.uid);
        table_section_data.element.fields.splice(index, 1);

        table_column_config_popup.close();
        form_builder_table_field_popup.close();
        openTableColumnConfig(table_section_data);
      },
      submit(response_field) {
        const index = table_section_data.element.fields.findIndex(field => field.uid === response_field.uid);

        if (index >= 0)
          table_section_data.element.fields[index] = response_field;
        else
          table_section_data.element.fields.push(response_field);

        table_column_config_popup.close();
        form_builder_table_field_popup.close();
        openTableColumnConfig(table_section_data);
      },
    },
  });
  form_builder_table_field_popup.open();
}

function openAddTableColumnPopup(table_section_data) {
  form_builder_table_field_popup.patchOptions({
    attrs: {
      fields: table_section_data.element.fields,
      section: table_section_data.element,
      selected_column: null,
      onClose() {
        form_builder_table_field_popup.close();
      },
      submit(response_field) {
        const index = table_section_data.element.fields.findIndex(field => field.uid === response_field.uid);

        if (index >= 0)
          table_section_data.element.fields[index] = response_field;
        else
          table_section_data.element.fields.push(response_field);

        table_column_config_popup.close();
        form_builder_table_field_popup.close();
        openTableColumnConfig(table_section_data);
      },
    },
  });
  form_builder_table_field_popup.open();
}

function openTableColumnConfig(table_section_data) {
  table_column_config_popup.patchOptions({
    attrs: {
      onClose() {
        table_column_config_popup.close();
      },
      async update(arranged_fields) {
        try {
          const { data } = await $services.forms.patch({
            attribute: 'fields/reorder',
            body: { fields: arranged_fields.map(field => field.uid) },
            toast: false,
          });

          table_section_data.element.fields = data.fields;
        }
        catch (error) {
          logger.error(error);
          $toast({ text: $t('Failed to configure columns'), type: 'error' });
        }
      },
      onAddColumn() {
        openAddTableColumnPopup(table_section_data);
      },
      onEditColumn(selected_column) {
        openEditTableColumnPopup(table_section_data, selected_column);
      },
      selected_items: table_section_data.element.fields.filter(field => field.status === 'active'),
    },
  });

  table_column_config_popup.open();
}

onMounted(() => {
  form_template_detail_store.get_section_templates();
});
</script>

<template>
  <div class="bg-gray-50 rounded-lg p-4">
    <Vueform
      ref="form$" v-model="form" :display-errors="false" :presets="['disable_error_messages']"
      :add-classes="{ ElementDescription: { container: 'pointer-events-auto field_description' } }"
      :remove-classes="{ ElementLabel: { container_horizontal_sm: 'text-type:form-pt-input-border-sm', container_horizontal_sm_SM: 'sm:text-type:form-pt-input-border-sm' } }"
      :columns="{
        container: 12,
        label: 4,
        wrapper: 12,
      }" size="sm"
    >
      <div class="col-span-12">
        <FormBuilderNewSectionMenu
          v-if="active_sections.length === 0"
          @select_section="emits('select_section', { section: $event.section, section_index: $event.index })"
        />
        <div v-if="form_template_detail_store.sections && form_template_detail_store.sections.length > 0">
          <draggable
            :is="draggable"
            :group="{ name: 'sections' }" :list="form_template_detail_store.sections" handle=".section-handle"
            item-key="uid" tag="div" @change="sectionMoved($event)"
          >
            <template #item="section">
              <div
                v-if="section.element.status === 'active' && (+section.element.step_index === +form_template_detail_store.step_number || form_template_detail_store.step_number === -1)"
                class="section" @click="$event => selectSection(section, $event)"
                :key="section.element"
              >
                <div class="relative">
                  <div class="absolute top-[25px] -left-[32px] cursor-grab">
                    <hawk-button type="plain" class="options invisible section-handle">
                      <span class="text-lg  text-gray-300 "><icon-hawk-drag-icon class="h-4 w-4 text-gray-300" /></span>
                    </hawk-button>
                  </div>
                  <template v-if="!section.element.uid && form_template_detail_store.is_loading_section_templates">
                    <HawkLoader />
                  </template>
                  <template v-else>
                    <div
                      class="p-4 border rounded-lg bg-white hover:border-blue-500"
                      :class="selected_section?.section === section.element ? 'border-blue-500 shadow-md' : ' border-gray-300'"
                    >
                      <!----- Section-header ----->
                      <div class="flex justify-between my-3 ml-[2rem]">
                        <div>
                          <div class="font-semibold text-lg">
                            <div v-if="section.element.name">
                              {{ section.element.name }}
                            </div>
                            <div v-else class="opacity-70 text-lg">
                              {{ $t('Untitled Section') }}
                            </div>
                          </div>
                          <div
                            v-if="section.element.description" id="section_description"
                            class="text-xs text-gray-600"
                          >
                            {{ section.element.description }}
                          </div>
                          <div v-else id="section_description" class="text-xs text-gray-500">
                            {{ $t('Add Description') }}
                          </div>
                        </div>
                        <div
                          v-if="section.element.uid"
                          class="justify-end relative flex items-center invisible options hover:bg-gray-50 rounded-lg"
                          @click.stop
                        >
                          <hawk-button
                            v-if="section.element.type === 'tablev2'" v-tippy="$t('Configure columns')"
                            icon type="plain" class="hover:!bg-gray-200 mr-1" @click="openTableColumnConfig(section)"
                          >
                            <IconHawkTableEdit class="text-lg" />
                          </hawk-button>
                          <hawk-button
                            icon type="plain" :loading="is_duplicating" class="hover:!bg-gray-200 mr-1"
                            @click="duplicateSection(section.element)"
                          >
                            <CopyIcon class="text-lg" />
                          </hawk-button>
                          <div
                            v-tippy="{ content: active_sections_with_uid.length === 1 ? $t('Cannot delete the only section. Please add another section to continue.') : '' }"
                          >
                            <hawk-button
                              icon type="plain" :loading="is_deleting"
                              :disabled="active_sections_with_uid.length === 1" class="hover:!bg-gray-200 mr-1"
                              @click="deleteSection(section)"
                            >
                              <TrashIcon class="text-lg" />
                            </hawk-button>
                          </div>
                          <div v-if="section.element.type === 'default' || section.element.type === 'tablev2'">
                            <hawk-menu
                              additional_trigger_classes="!ring-0"
                              :items="[{ label: $t('Save as template'), on_click: () => saveTemplate(section) }, { label: $t('Load from template'), on_click: () => loadTemplate(section) }]"
                            >
                              <template #trigger>
                                <hawk-button class="hover:!bg-gray-200 mr-1" icon type="plain">
                                  <icon-hawk-dots-vertical class="text-lg" />
                                </hawk-button>
                              </template>
                            </hawk-menu>
                          </div>
                        </div>
                      </div>
                      <!----- Section-header ----->
                      <FormBuilderFields
                        :key="section.element.fields"
                        :section="section"
                        :draggable="draggable"
                        :selected_field="props.selected_field"
                        @select_field="emits('select_field', $event ? { field: $event.element, form$, field_index: $event.index, section_index: section.index, section: section.element, focus_description: $event.focus_description, counter: $event.counter } : null)"
                        @duplicate_field="duplicate_field(field.element, section.element)"
                      />
                    </div>
                    <FormBuilderNewSectionMenu
                      :section="section"
                      @select_section="emits('select_section', { section: $event.section, section_index: $event.index })"
                    />
                  </template>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </Vueform>
  </div>
</template>

<style lang="scss" scoped>
.section:hover {
  .options {
    visibility: visible;
  }
}

.fields-list:empty {
  text-align: center;
  padding-bottom: 3rem;
  padding-top: 0.5rem;

  &:after {
    content: "Drag and drop fields here";
    opacity: 0.7;
  }
}
</style>
