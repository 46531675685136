<script>
import { ref, toRefs } from 'vue';
import dayjs from 'dayjs';
import VueformElement from '@vueform/vueform/element';
import '@vuepic/vue-datepicker/dist/main.css';

export default VueformElement({
  name: 'DateTimeElement',
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
}, {
  components: {
    VueDatePicker: defineAsyncComponent(() => import('@vuepic/vue-datepicker')),
  },
  emits: [
    'initialized',
    'update:model-value',
    'text-submit',
    'open',
    'closed',
    'cleared',
    'focus',
    'blur',
    'internal-model-change',
    'recalculate-position',
    'flow-step',
    'update-month-year',
    'invalid-select',
    'invalid-fixed-range',
    'tooltip-open',
    'tooltip-close',
    'time-picker-open',
    'time-picker-close',
    'am-pm-change',
    'range-start',
    'range-end',
    'update:model-timezone-value',
  ],
  setup(props, { element, emit }) {
    const defaultClasses = ref({
      container: '',
      date_time: '',
      $date_time: classes => ([
        classes.date_time,
      ]),
    });

    const refs = {
      vue_date_picker_ref: ref(null),
    };

    const { options } = toRefs(props);

    const config_options = {
      'enableTimePicker': false,
      'format': options.value.enableTimePicker ? 'MMMM dd, yyyy h:mm aa' : 'MMMM dd, yyyy',
      'teleport': true,
      'auto-apply': true,
      ...options.value,
    };

    const date_time = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        element.model.value = value;
      },
    });

    const day_js = dayjs;

    onMounted(() => {
      emit('initialized', refs.vue_date_picker_ref);
    });

    return {
      defaultClasses,
      date_time,
      day_js,
      ...refs,
      config_options,
    };
  },
});
</script>

<template>
  <!-- eslint-disable vue/custom-event-name-casing vue/no-unused-refs -->

  <ElementLayout>
    <template #element>
      <VueDatePicker
        v-bind="config_options"
        ref="vue_date_picker_ref"
        v-model="date_time"
        month-name-format="long"
        @update:model-value="$emit('update:model-value', $event)"
        @text-submit="$emit('text-submit')"
        @open="$emit('open')"
        @closed="$emit('closed')"
        @cleared="$emit('cleared')"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @internal-model-change="$emit('internal-model-change', $event)"
        @recalculate-position="$emit('recalculate-position')"
        @flow-step="$emit('flow-step', $event)"
        @update-month-year="$emit('update-month-year', $event)"
        @invalid-select="$emit('invalid-select', $event)"
        @invalid-fixed-range="$emit('invalid-fixed-range', $event)"
        @tooltip-open="$emit('tooltip-open', $event)"
        @tooltip-close="$emit('tooltip-close', $event)"
        @time-picker-open="$emit('time-picker-open')"
        @time-picker-close="$emit('time-picker-close')"
        @am-pm-change="$emit('am-pm-change', $event)"
        @range-start="$emit('range-start', $event)"
        @range-end="$emit('range-end', $event)"
        @update:model-timezone-value="$emit('update:model-timezone-value', $event)"
      >
        <template #calendar-header="{ index }">
          {{ day_js().day(index + 1).format("ddd") }}
        </template>
      </VueDatePicker>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <!-- eslint-disable-next-line vue/valid-attribute-name -->
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>

<style scoped>
.has-error :deep(.dp__input_focus) {
  @apply !border-error-600 !ring-error-100;
}
.has-error :deep(.dp__input) {
  @apply !border-error-600;
}

:deep(.dp__input) {
  @apply placeholder-gray-900;
}

/* Default colour is very faded */
:global(.dp__pm_am_button) {
  @apply text-gray-700;
}
</style>

<style lang="scss">
.dp__action_cancel {
  @apply inline-flex;
  @apply items-center;
  @apply border;
  @apply shadow-sm;
  @apply relative;
  @apply rounded-lg;
  @apply focus:outline-none;
  @apply bg-white;
  @apply border-gray-300;
  @apply px-3;
  @apply py-2;
  @apply text-sm;
  @apply font-medium;
  @apply h-9;
  &:hover {
    @apply border;
    @apply bg-gray-100;
    @apply text-gray-800;
    @apply border-gray-300;
  }
}

.dp__action_select {
  @apply bg-primary-600 #{!important};
  @apply inline-flex;
  @apply items-center;
  @apply shadow-sm;
  @apply relative;
  @apply rounded-lg;
  @apply font-medium;
  @apply focus:outline-none;
  @apply px-3;
  @apply py-2;
  @apply text-sm;
  @apply h-9;
  @apply text-white;
  @apply ml-3;
  &:hover {
    @apply bg-primary-700 #{!important};
  }
}
</style>
