<script>
import VueformElement from '@vueform/vueform/element';
import { ref } from 'vue';

export default VueformElement({
  name: 'LnsCheckboxTristateElement',
}, {
  setup(props, { attrs, element }) {
    const defaultClasses = ref({
      container: '',
    });

    const state = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        element.model.value = value;
      },
    });
    const is_disabled = computed(() => element.isDisabled.value);
    const specific_values = computed(() => attrs.specific_values);

    return {
      defaultClasses,
      state,
      is_disabled,
      specific_values,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <lns-hawk-checkbox-tristate
        v-model="state"
        :disabled="is_disabled"
        :specific_values="specific_values"
      />
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <!-- eslint-disable-next-line vue/valid-attribute-name -->
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
