const LnsBooksList = () => import('~/lns/pages/lns-books-list.vue');
const LnsBookDetails = () => import('~/lns/pages/lns-book-details.vue');

const routes = [
  {
    path: '/:asset_id/books/',
    name: 'lns-books-list',
    component: LnsBooksList,
    meta: {
      title: 'Spreadsheet List',
    },
  },
  {
    path: '/:asset_id/books/:book_uid',
    name: 'lns-book-details',
    component: LnsBookDetails,
    meta: {
      title: 'Spreadsheet Details',
    },
  },
];

export default routes;
