<!-- eslint-disable prefer-rest-params -->
<!-- eslint-disable no-alert -->
<script setup>
import dayjs from 'dayjs';
import parser from 'dayjs-parser';
import dompurify from 'dompurify';
import { isFinite, isNaN, keyBy } from 'lodash-es';
import { onMounted, onUnmounted } from 'vue';
import { useMembers } from '~/common/composables/members.js';
import { useCommonStore } from '~/common/stores/common.store';
import { getUserFullName } from '~/common/utils/common.utils';
import { load_js_css_file } from '~/common/utils/load-script.util';

const props = defineProps({
  hotSettings: {
    type: Object,
    default: () => ({}),
  },
  data: {
    type: Array,
    required: true,
  },
  columns: {
    type: Array,
    default: () => [],
  },
  hotTableId: {
    type: String,
    default: 'hawk-handsontable',
  },
  triStateValuesMap: {
    type: Object,
    default: () => ({
      yes: 'Yes',
      no: 'No',
      intermediate: 'N/A',
    }),
  },
  columnsMenu: {
    type: Object,
    default: () => (null),
  },
  cells: {
    type: Function,
    default: null,
  },
  height: {
    type: String,
    default: '600px',
  },
  width: {
    type: String,
    default: '100%',
  },
  nestedHeaders: {
    type: Array,
    default: () => [],
  },
  manualRowMove: {
    type: Boolean,
    default: true,
  },
  enableSlugs: {
    type: Boolean,
    default: false,
  },
  manualColumnResize: {
    type: Boolean,
    default: false,
  },
  fillHandle: {
    type: String,
    default: 'vertical',
  },
  manualColumnMove: {
    type: Boolean,
    default: true,
  },
  autoWrapCol: {
    type: Boolean,
    default: true,
  },
  addNewRowOnEnter: {
    type: Boolean,
    default: true,
  },
  autoWrapRow: {
    type: Boolean,
    default: true,
  },
  rowHeaderIcon: {
    type: Boolean,
    default: false,
  },
  rowHeaderIconTooltipText: {
    type: String,
    default: 'Info',
  },
  wordWrap: {
    type: Boolean,
    default: true,
  },
  rowSorting: {
    type: Boolean,
    default: true,
  },
  contentWrap: {
    type: Boolean,
    default: true,
  },
  columnSummaryConfig: {
    type: Object,
    default: () => {
      return {
        show: false,
        type: 'column',
        summaryFunction: () => ({}),
      };
    },
  },
  colHeaders: Function,
  afterChange: Function,
  beforeChange: Function,
  afterOnCellMouseDown: Function,
  afterValidate: Function,
  beforeValidate: Function,
  beforeRemoveRow: Function,
  afterRemoveRow: Function,
  afterSelection: Function,
  afterSelectionEnd: Function,
  beforeOnCellMouseDown: Function,
  afterGetColHeader: Function,
});
const emit = defineEmits(['ready', 'insertColLeft', 'insertColRight', 'deleteCol', 'deleteRow', 'afterChange', 'afterCellEdit', 'afterRowEdit', 'slugClicked', 'rowHeaderIconClick', 'cellDblClick', 'onEnterPress', 'afterColumnsDragged', 'afterRowsDragged', 'afterColumnsResized', 'addRowOnEnter']);
dayjs.extend(parser);
const { getUserDetails } = useMembers();
const common_store = useCommonStore();
const state = reactive({
  hotInstance: null,
});
const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];
const users_and_teams = computed(() => {
  const users_by_name = keyBy(common_store.users.map((item) => {
    return {
      ...item,
      name: getUserFullName(item),
    };
  }), 'name');
  const teams_by_name = keyBy(common_store.teams.map((item) => {
    return {
      ...item,
      name: getUserFullName(item),
    };
  }), 'name');
  return { ...teams_by_name, ...users_by_name };
});
function isSummaryRow(row, instance, _cellProperties) {
  if (!props.columnSummaryConfig?.show)
    return;
  if (props.columnSummaryConfig?.type === 'column') {
    return row === instance?.countRows() - 1;
  }
  else if (props.columnSummaryConfig?.type === 'detailed') {
    return row > instance.countRows() - props.columnSummaryConfig?.summaryRowsLength - 1;
  }
}
function stringToNumber(string) {
  let total = 0;
  for (const char of string)
    total += char.charCodeAt(0);

  return color_set[total % color_set.length];
}

function phoneNumberRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const countryCode = value.code || '';
  const phoneNumber = value.number || '';
  td.innerHTML = `+${countryCode} - ${phoneNumber}`;
  td.className = 'htMiddle htCenter';
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }
  return td;
}

function urlRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const anchor = document.createElement('a');
  anchor.href = value?.includes('https://') ? value : `https://${value}`;
  anchor.textContent = value;
  anchor.target = '_blank';
  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }
  td.appendChild(anchor);
  return td;
}

function emailRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const anchor = document.createElement('a');
  anchor.href = `mailto:${value}`;
  anchor.textContent = value;
  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }
  td.appendChild(anchor);
  return td;
}

function fileUploadRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const grid = document.createElement('div');
  grid.style.display = 'grid';
  grid.style.gridTemplateColumns = 'repeat(3, 1fr)';
  grid.style.gridGap = '4px';
  grid.style.padding = '4px';

  value?.forEach((fileUrl) => {
    let url = fileUrl;
    if (typeof url !== 'string') {
      url = fileUrl.service?.url;
    }
    const img = document.createElement('img');
    img.src = url;
    img.style.width = '75px';
    img.style.height = '75px';
    img.style.borderRadius = '4px';
    img.style.objectFit = 'cover';
    grid.appendChild(img);
  });

  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }
  td.appendChild(grid);
  return td;
}

function signatureRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const user = getUserDetails(value?.meta?.owner?.uid);
  td.innerHTML = `${user.name || 'No name'} [${new Date(value?.meta?.created_at).toLocaleDateString()}]`;
  td.className = 'htMiddle htCenter';
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }

  return td;
}

function membersRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }
  const { members_details } = getUserDetails(value);
  members_details?.forEach((user) => {
    if (user) {
      const display_name = user.name;

      const user_details = {
        bg_color: stringToNumber(user?.name),
        label: dompurify.sanitize(display_name.trim(), { ALLOWED_TAGS: [] }),
        id: user.uid,
        avatar: user?.display_picture ?? '',
        email: dompurify.sanitize(user?.email, { ALLOWED_TAGS: [] }),
      };

      const container = document.createElement('div');
      container.style.display = 'flex';
      container.classList = 'gap-1';
      container.style.alignItems = 'center';
      container.style.marginBottom = '4px';
      if (user_details.avatar) {
        const img = document.createElement('img');
        img.src = user?.display_picture ?? '';
        img.className = 'rounded-full object-cover';
        img.style.width = '24px';
        img.style.height = '24px';
        container.appendChild(img);
      }
      else {
        const span = document.createElement('span');
        span.className = 'rounded-full';
        span.style.width = '24px';
        span.style.height = '24px';
        span.style.backgroundColor = user_details?.bg_color;
        span.textContent = user_details.label?.substring(0, 1);
        span.style.color = 'white';
        span.style.alignContent = 'center';
        container.appendChild(span);
      }
      const name = document.createElement('span');
      name.textContent = user_details.label;

      container.appendChild(name);
      td.appendChild(container);
    }
  });
  return td;
}

function multiselectRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties)) {
    return td;
  }

  Handsontable.dom.empty(td);

  const ul = document.createElement('ul');

  ul.style.margin = '0';
  ul.style.listStyle = 'inside';
  if (Array.isArray(value)) {
    value.forEach((item) => {
      const li = document.createElement('li');
      li.textContent = item;
      li.style.display = 'list-item';
      ul.appendChild(li);
    });
  }

  td.appendChild(ul);

  td.className = 'htMiddle htCenter';
  if (cellProperties.readOnly) {
    td.className += ' read-only-cell';
  }
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }

  return td;
}

function triStateRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }
  const checkbox = document.createElement('input');
  checkbox.type = 'checkbox';
  checkbox.style.width = '14px';
  checkbox.style.height = '14px';

  const label = document.createElement('span');

  if (value?.toLowerCase() === props.triStateValuesMap.yes?.toLowerCase()) {
    checkbox.checked = true;
  }

  else if (value?.toLowerCase() === props.triStateValuesMap.no?.toLowerCase()) {
    checkbox.classList.add('custom-no-state');
  }

  else if (value?.toLowerCase() === props.triStateValuesMap.intermediate?.toLowerCase()) {
    checkbox.indeterminate = true;
    checkbox.classList.add('custom-intermediate-state');
  }
  if (!cellProperties.readOnly) {
    checkbox.addEventListener('click', () => {
      let currentValue = value;

      if (currentValue === '' || currentValue === '-')
        currentValue = props.triStateValuesMap.yes;
      else if (currentValue?.toLowerCase() === props.triStateValuesMap.yes?.toLowerCase())
        currentValue = props.triStateValuesMap.no;
      else if (currentValue?.toLowerCase() === props.triStateValuesMap.no?.toLowerCase())
        currentValue = props.triStateValuesMap.intermediate;
      else if (currentValue?.toLowerCase() === props.triStateValuesMap.intermediate?.toLowerCase())
        currentValue = '';
      state.hotInstance.getActiveEditor()?.enableFullEditMode();
      state.hotInstance.getActiveEditor()?.beginEditing(state.hotInstance.getDataAtCell(row, col));
      instance.setDataAtCell(row, col, currentValue);
    });
  }
  td.appendChild(checkbox);
  td.appendChild(label);
}

function multiTextRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  td.innerHTML = value;
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }
  return td;
}
function customRowHeaderRenderer(row, TH) {
  TH.className = 'htMiddle htCenter';

  // Check for summary row and apply specific class
  if (props.columnSummaryConfig?.show && isSummaryRow(row, this, {})) {
    TH.innerHTML = '';
    TH.className = 'summaryRow';
    return;
  }

  // Only add row icon and tooltip if it doesn't already exist
  if (!TH.querySelector('.row-icon') && props.rowHeaderIcon) {
    TH.innerHTML = '';

    // Create a container to hold the row number and icon
    const container = document.createElement('div');
    container.style.display = 'flex';
    container.style.alignItems = 'center';
    container.style.justifyContent = 'center';

    // Create a row number element
    const rowNumber = document.createElement('span');
    rowNumber.textContent = row + 1;

    // Create the icon element
    const icon = document.createElement('span');
    icon.classList.add('row-icon', 'ml-[5px]', 'cursor-pointer');
    icon.innerHTML = '⛶';
    icon.style.fontSize = '16px';
    icon.style.height = '20px';
    icon.style.position = 'relative'; // For tooltip positioning

    // Create a tooltip element for the icon
    const iconTooltip = document.createElement('span');
    iconTooltip.classList.add('row-icon-tooltip');
    iconTooltip.textContent = props.rowHeaderIconTooltipText; // Tooltip text with row index
    iconTooltip.style.position = 'absolute';
    iconTooltip.style.top = '-30px'; // Adjust positioning above the icon
    iconTooltip.style.left = '50%';
    iconTooltip.style.transform = 'translateX(-50%)';
    iconTooltip.style.visibility = 'hidden'; // Initially hidden
    iconTooltip.style.backgroundColor = '#555';
    iconTooltip.style.color = '#fff';
    iconTooltip.style.padding = '5px';
    iconTooltip.style.whiteSpace = 'nowrap'; // Keep text in a single line
    iconTooltip.style.zIndex = '999';
    iconTooltip.style.opacity = '0';
    iconTooltip.style.transition = 'opacity 0.3s, visibility 0.3s';

    icon.addEventListener('mouseenter', () => {
      iconTooltip.style.visibility = 'visible';
      iconTooltip.style.opacity = '1';
    });

    icon.addEventListener('mouseleave', () => {
      iconTooltip.style.visibility = 'hidden';
      iconTooltip.style.opacity = '0';
    });

    icon.appendChild(iconTooltip);

    container.appendChild(rowNumber);
    container.appendChild(icon);

    TH.appendChild(container);

    // Add click event to the icon
    icon.addEventListener('click', (event) => {
      event.stopPropagation();
      alert(`Icon clicked on row: ${row}`);
      emit('rowHeaderIconClick', row);
    });
  }
}

function customDateRenderer(instance, td, row, col, prop, value, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, arguments);

  if (!value) {
    td.innerHTML = '';
    return td;
  }

  let formattedValue = '';

  if (cellProperties.type === 'date') {
    formattedValue = dayjs(value).format('D MMMM YYYY');
  }
  else if (cellProperties.dateTime) {
    formattedValue = dayjs(value).format('D MMMM YYYY hh:mm A');
  }

  td.innerHTML = formattedValue;
  return td;
}

function suffixPrefixRenderer(instance, td, row, col, prop, value, cellProperties) {
  const type = cellProperties.type;
  if (type === 'text')
    Handsontable.renderers.TextRenderer.apply(this, arguments);
  else if (type === 'numeric')
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  td.innerHTML = `${cellProperties.prefix ? `<span>${cellProperties.prefix}</span> ` : ''}${value || ''}${cellProperties.suffix ? ` <span>${cellProperties.suffix}</span>` : ''}`;
}

function formatDate(value) {
  const parsedDate = dayjs(value);
  if (parsedDate.isValid()) {
    return parsedDate.format('DD/MM/YYYY');
  }
  return '';
}
function formatDateTime(value) {
  const parsedDate = dayjs(value);
  if (parsedDate.isValid()) {
    return parsedDate.format('D MMMM YYYY hh:mm A');
  }
  return '';
}
function convertToNumberAndCheck(input) {
  if (typeof input === 'string' && input.length === 0)
    return { isValid: false, value: input };
  // Remove any commas from the input string
  const cleanedInput = input.replace(/,/g, '');

  // Convert the cleaned string to a number
  const number = Number(cleanedInput);

  // Check if the conversion was successful and if the result is a finite number
  if (!isNaN(number) && isFinite(number)) {
    return {
      isValid: true,
      number,
    };
  }
  else {
    return {
      isValid: false,
      number: null,
    };
  }
}

// function formatTime(value) {
//   const parsedTime = dayjs(value);
//   if (parsedTime.isValid()) {
//     return parsedTime.format('hh:mm A');
//   }
//   return '';
// }
function handleDblClick() {
  const selected_cell = state.hotInstance.getSelectedLast();
  const row = selected_cell?.[0];
  const column = selected_cell?.[1];
  if (row === -1)
    return;
  const cellMeta = state.hotInstance.getCellMeta(row, column);

  emit('cellDblClick', { ...(cellMeta || {}), value: state.hotInstance?.getDataAtCell(row, column) });
}
function fixedRowsBasedOnSummaryConfig() {
  if (props.fixedRowsBottom)
    return props.fixedRowsBottom;
  if (props.columnSummaryConfig?.show && props.columnSummaryConfig?.type === 'column') {
    return 1;
  }
  if (props.columnSummaryConfig?.show && props.columnSummaryConfig?.type === 'detailed') {
    return props.columnSummaryConfig?.summaryRowsLength;
  }
  return null;
}
function handleRegisterValidators() {
  function customValidator(value, callback) {
    // eslint-disable-next-line ts/no-this-alias
    const cell_properties = this;

    const column_config = cell_properties.instance.getSettings().columns?.[cell_properties.col]?.config || {};

    let is_valid = true;

    if (column_config.required) {
      if (value === null || value === undefined) {
        is_valid = false;
      }
      else if (typeof value === 'string') {
        is_valid = value.trim() !== '';
      }
      else if (Array.isArray(value)) {
        is_valid = value?.length > 0;
      }
      else {
        is_valid = !Number.isNaN(value) && value !== '';
      }
    }

    if (!is_valid)
      callback(false);

    if (column_config.field_type === 'text') {
      if (is_valid && column_config.min)
        is_valid = value?.trim()?.length >= column_config.min;
      if (is_valid && column_config.max)
        is_valid = value?.trim()?.length <= column_config.max;
    }

    if (column_config.field_type === 'numeric') {
      if (is_valid && column_config.min)
        is_valid = value >= column_config.min;
      if (is_valid && column_config.max)
        is_valid = value <= column_config.max;
    }

    if (column_config.field_type === 'multi-select') {
      if (is_valid && column_config.min)
        is_valid = value?.length >= column_config.min;
      if (is_valid && column_config.max)
        is_valid = value?.length <= column_config.max;
    }

    if (column_config.field_type === 'email') {
      const email_pattern = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/;
      is_valid = email_pattern.test(value);
    }

    if (column_config.field_type === 'phonenumber') {
      const phone_pattern = /^\+?\d{1,3}\s\d{1,14}$/;
      is_valid = phone_pattern.test(value);
    }

    if (column_config.field_type === 'url') {
      // eslint-disable-next-line regexp/no-unused-capturing-group
      const url_pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
      is_valid = url_pattern.test(value);
    }

    callback(is_valid);
  }

  // Register the custom validator with an alias
  Handsontable.validators.registerValidator('default-validator', customValidator);
}
function getCustomFunctions() {
  return {
    validateTable: () => state.hotInstance.validateCells(),
  };
}
async function loadScripts() {
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@14.6.0/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@14.6.0/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );
}
async function initHandsontable() {
  const triStateCheckboxEditorModule = await import('~/common/components/organisms/hawk-handsontable/editors/tristate-editor.js');
  const TriStateCheckboxEditor = triStateCheckboxEditorModule.default;
  Handsontable.editors.registerEditor('triStateCheckboxEditor', class extends TriStateCheckboxEditor {
    constructor(hotInstance) {
      super(hotInstance);
      this.setValuesMap(props.triStateValuesMap);
    }
  });
  const multiSelectCheckboxEditorModule = (await import('~/common/components/organisms/hawk-handsontable/editors/multiselect-editor.js'));
  const MultiSelectCheckboxEditor = multiSelectCheckboxEditorModule.default;
  Handsontable.editors.registerEditor('multiSelectAutocompleteEditor', MultiSelectCheckboxEditor);

  const hotSettings = {
    data: props.data?.length ? props.data : null,
    columns: props.columns,
    height: props.height,
    width: props.width,
    afterChange(changes) {
      if (changes) {
        changes?.forEach(([row, _prop, _oldValue, _newValue]) => {
          emit('afterRowEdit', state.hotInstance?.getSourceDataAtRow(row));
        });
        emit('afterChange', state.hotInstance?.getSourceData());
      }
    },
    beforeChange(changes, source) {
      if (source !== 'edit' && source !== 'loadData') {
        changes.forEach((change, index) => {
          const [row, prop] = change;
          const col = state.hotInstance.propToCol(prop);
          const cellMeta = state.hotInstance.getCellMeta(row, col);
          if (['signatureRenderer', 'fileUploadRenderer'].includes(cellMeta.renderer))
            changes[index] = null;
        });
      }
    },
    afterRowMove: async (movedRows, finalIndex, dropIndex, movePossible, orderChanged) => {
      if (!orderChanged)
        return;

      const data = [];
      for (let row = 0; row < state.hotInstance.getData().length; row++) {
        const obj = {};
        for (let i = 0; i < state.hotInstance.countCols(); i++) {
          obj[state.hotInstance.colToProp(i)] = state.hotInstance.getDataAtCell(row, i);
        }
        data.push(obj);
      }

      emit('afterRowsDragged', data);
    },
    afterColumnMove: (movedColumns, finalIndex, dropIndex, movePossible, orderChanged) => {
      if (orderChanged) {
        emit('afterColumnsDragged', state.hotInstance.getColHeader());
      }
    },
    afterColumnSort() {
      if (props.columnSummaryConfig?.show) {
        const summaryRowsLength = props.columnSummaryConfig?.summaryRowsLength || 1;
        const totalRows = state.hotInstance.countRows();

        for (let i = 0; i < summaryRowsLength; i++) {
          const visualSummaryRow = state.hotInstance.toVisualRow(totalRows - 1 - i);
          const targetIndex = totalRows - 1 - i;

          state.hotInstance.rowIndexMapper.moveIndexes(visualSummaryRow, targetIndex);
        }
      }
    },
    afterOnCellMouseDown: props.afterOnCellMouseDown,
    afterValidate: props.afterValidate,
    rowHeaders: true,
    dropdownMenu: props.enableSlugs
      ? false
      : props.columnsMenu || {
        items: {
          add_col_left: {
            name: 'Insert column left',
            disabled() {
              return false;
            },
            callback(key) {
              emit('insertColLeft', key);
              alert('Insert column left callback');
            },
            hidden() {
              return state.hotInstance.getSelected()[1] === 0;
            },
          },
          add_col_right: {
            name: 'Insert column right',
            disabled() {
              return false;
            },
            hidden() {
              return state.hotInstance.getSelected()[1] === 0;
            },
            callback(key) {
              emit('insertColRight', key);
              alert('Insert column right callback');
            },
          },
          clear_column: {
            name: 'Clear column',
          },
          delete_col: {
            name: 'Delete column',
            hidden() {
              return state.hotInstance.getSelected()[1] === 0;
            },
            disabled() {
              return true;
            },
            callback(key) {
              emit('deleteCol', key);
              alert('Delete column callback');
            },

          },
        },
      },
    fixedRowsBottom: fixedRowsBasedOnSummaryConfig(),
    ...(props.columnSummaryConfig?.show ? { columnSummary: props.columnSummaryConfig?.summaryFunction } : {}),
    columnSorting: props.rowSorting,
    viewportRowRenderingOffset: 1000,
    beforeValidate: props.beforeValidate,
    beforeRemoveRow: props.beforeRemoveRow,
    readOnlyCellClassName: 'read-only-cell',
    afterRemoveRow: props.afterRemoveRow,
    manualColumnResize: props.manualColumnResize,
    afterSelection: props.afterSelection,
    startRows: props.startRows ?? 5,
    beforeOnCellMouseDown: props.beforeOnCellMouseDown,
    manualRowMove: props.manualRowMove,
    autoWrapCol: props.autoWrapCol,
    autoWrapRow: props.autoWrapRow,
    undo: true,
    manualColumnMove: props.manualColumnMove,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    colHeaders: props.colHeaders,
    fillHandle: props.fillHandle,
    rowHeights: '36px',
    stretchH: 'all',

    afterColumnResize() {
      const columnWidths = [];

      for (let i = 0; i < state.hotInstance.countCols(); i++) {
        columnWidths.push(state.hotInstance.getColWidth(i));
      }
      emit('afterColumnsResized', columnWidths);
    },
    cells(row, _col, _prop) {
      const cellProperties = {};
      const column_name = this.instance.colToProp(_col);

      if ((this.instance.getDataAtRowProp(row, 'disableColumns') || [])?.includes(column_name) || this.instance.getDataAtRowProp(row, 'disableEdit') === true)
        cellProperties.readOnly = true;
      if (isSummaryRow(row, this.instance, cellProperties)) {
        cellProperties.readOnly = true;
        cellProperties.type = 'text';
        cellProperties.className = 'summaryRow htMiddle htCenter';
        cellProperties.validator = false;
      }
      return cellProperties;
    },
    afterGetColHeader(col, TH) {
      function createIcon(on_hover = true, callback = () => null) {
        const colMenu = document.createElement('div');
        TH.classList.add('relative');
        if (on_hover) {
          TH.classList.add('group');
          colMenu.classList.add('opacity-0', 'group-hover:opacity-100');
        }
        colMenu.classList.add(
          `column-menu-${col}`,
          'cursor-pointer',
          'absolute',
          'right-2',
          'top-1/2',
          'transform',
          '-translate-y-1/2',
          'px-2',
          'hover:bg-gray-200',
          'rounded-md',
          'transition-opacity',
          'duration-200',
        );

        colMenu.textContent = '⋮';
        if (props.enableSlugs) {
          colMenu.textContent = '</>';
          colMenu.classList.add('text-primary-600', '!text-[10px]');
        }

        TH.appendChild(colMenu);

        colMenu.addEventListener('mousedown', (event) => {
          event.stopPropagation();
        });

        colMenu.addEventListener('click', (event) => {
          event.stopPropagation();
          callback(event);
        });
      }

      const existingIcons = TH.querySelectorAll(`.column-menu-${col}`);
      existingIcons.forEach(icon => icon.remove());

      if (col > -1 && !TH.querySelector(`.column-menu-${col}`)) {
        if (props.enableSlugs) {
          createIcon(false, () => emit('slugClicked', col));
        }
      }
    },
    afterInit() {
      const colWidthsArray = props.columns.map((column, index) => {
        const columnWidth = this.getColWidth(index);
        return column.width || Math.min(columnWidth, 300);
      });
      this.updateSettings({
        colWidths: colWidthsArray,
      });
    },
    beforeCopy(data, coords) {
      let dataRowIndex = 0;
      for (let i = 0; i < coords.length; i++) {
        const startRow = coords[i].startRow;
        const endRow = coords[i].endRow;
        const startCol = coords[i].startCol;
        const endCol = coords[i].endCol;

        // Loop over each row in the selected range
        for (let row = startRow; row <= endRow; row++) {
          let dataColIndex = 0;

          // Loop over each column in the selected range
          for (let col = startCol; col <= endCol; col++) {
            const cellMeta = state.hotInstance.getCellMeta(row, col);
            let copiedValue = data[dataRowIndex % data.length][dataColIndex % data[0].length]; // Use modulus

            if (cellMeta.renderer === 'membersRenderer') {
              const users = getUserDetails(copiedValue);
              if (users?.members_details.length) {
                copiedValue = users?.members_details?.map(item => item.name)?.filter(item => item !== 'Unknown' && item !== 'Sensehawk' && item !== 'Sensehawk Bot');
              }
              data[dataRowIndex % data.length][dataColIndex % data[0].length] = copiedValue?.join(', ');
            }

            if (cellMeta.renderer === 'multiSelectRenderer') {
              data[dataRowIndex % data.length][dataColIndex % data[0].length] = copiedValue?.join(', ');
            }
            if (cellMeta.renderer === 'fileUploadRenderer') {
              data.splice(data[dataRowIndex % data.length], 1);
            }

            dataColIndex++;
          }

          dataRowIndex++;
        }
      }
    },
    beforePaste(data, coords) {
      let dataRowIndex = 0;

      for (let i = 0; i < coords.length; i++) {
        const startRow = coords[i].startRow;
        const endRow = coords[i].endRow;
        const startCol = coords[i].startCol;
        const endCol = coords[i].endCol;

        // Get the total number of rows and columns selected
        const selectedRowCount = endRow - startRow + 1;
        const selectedColCount = endCol - startCol + 1;

        // Get the total number of rows and columns in the pasted data
        const dataRowCount = data.length;
        const dataColCount = data[0].length;

        // Determine how many rows and columns we should loop through
        const totalRows = Math.max(selectedRowCount, dataRowCount);
        const totalCols = Math.max(selectedColCount, dataColCount);

        // Loop over each row, accommodating both the selected range and pasted data range
        for (let row = startRow; row < startRow + totalRows; row++) {
          let dataColIndex = 0; // Reset column index for each row

          // Loop over each column, accommodating both the selected range and pasted data range
          for (let col = startCol; col < startCol + totalCols; col++) {
            const cellMeta = state.hotInstance.getCellMeta(row, col);
            const pastedValue = data[dataRowIndex % data.length]?.[dataColIndex % data[0].length] || '';
            if (cellMeta.type === 'numeric') {
              if (convertToNumberAndCheck(pastedValue)?.isValid)
                data[dataRowIndex % data.length][dataColIndex % data[0].length] = pastedValue.replace(/[^0-9.]/g, '');
              else data.splice(data[dataRowIndex % data.length], 1);
            }

            if (cellMeta.renderer === 'membersRenderer') {
              let values = Array.isArray(pastedValue) ? pastedValue : pastedValue.split(', ');
              if (values.every(item => !users_and_teams.value[item]?.uid)) {
                data.splice(data[dataRowIndex % data.length], 1);
              }
              else {
                if (values.length) {
                  values = values.map(value => users_and_teams.value[value]?.uid || value);
                }

                data[dataRowIndex % data.length][dataColIndex % data[0].length] = values;
                if (!cellMeta.readOnly)
                  state.hotInstance.setDataAtCell(row, col, values);
              }
            }

            if (cellMeta.type === 'date') {
              if (dayjs(pastedValue).isValid())

                data[dataRowIndex % data.length][dataColIndex % data[0].length] = formatDate(pastedValue);
              else data.splice(data[dataRowIndex % data.length], 1);
            }

            if (cellMeta.dateTime) {
              if (dayjs(pastedValue).isValid())
                data[dataRowIndex % data.length][dataColIndex % data[0].length] = formatDateTime(pastedValue);
              else data.splice(data[dataRowIndex % data.length], 1);
            }
            if (cellMeta.renderer === 'multiSelectRenderer') {
              const values = pastedValue.split(', ');
              if (!values.every(value => cellMeta.options.includes(value)))
                data.splice(data[dataRowIndex % data.length], 1);
            }
            if (cellMeta.renderer === 'triStateRenderer') {
              if (!Object.values(props.triStateValuesMap).some(item => item.toLowerCase() === pastedValue.toLowerCase()))
                data.splice(data[dataRowIndex % data.length], 1);
            }
            if (cellMeta.type === 'dropdown') {
              if (!cellMeta.source.includes(pastedValue))
                data.splice(data[dataRowIndex % data.length], 1);
            }
            if (cellMeta.type === 'autocomplete') {
              if (!cellMeta.source.includes(pastedValue))
                data.splice(data[dataRowIndex % data.length], 1);
            }
            if (cellMeta.renderer === 'emailRenderer') {
              if (!pastedValue.match(/^[\w.!#$%&’*+/=?^`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i))
                data.splice(data[dataRowIndex % data.length], 1);
            }
            dataColIndex++; // Move to the next column in the data
          }

          dataRowIndex++; // Move to the next row in the data
        }
      }
      if (data.length === 0)
        return false;
    },

    beforeKeyDown(event) {
      if (props.addNewRowOnEnter) {
        let totalRows = this.countRows();
        let lastRow = totalRows - 1;
        if (isSummaryRow(lastRow, state.hotInstance)) {
          lastRow = lastRow - (props.columnSummaryConfig?.summaryRowsLength || 1);
          totalRows = totalRows - (props.columnSummaryConfig?.summaryRowsLength || 1);
        }

        const selected = this.getSelected();
        if (event.key === 'Enter' && selected[0][0] === lastRow) {
          event.preventDefault();
          const contextMenu = state.hotInstance.getPlugin('contextMenu');
          contextMenu.executeCommand('row_below');
          emit('addRowOnEnter');
          const currentCol = selected[0][1];
          setTimeout(() => {
            state.hotInstance.selectCell(totalRows, currentCol);
          }, 100);
        }
      }
    },

    afterDocumentKeyDown(event) {
      if (event.keyCode === 13) {
        const selected_cell = state.hotInstance.getSelectedLast();
        const row = selected_cell?.[0];
        const column = selected_cell?.[1];
        const cellMeta = state.hotInstance?.getCellMeta(row, column);
        emit('onEnterPress', { ...(cellMeta || {}), value: state.hotInstance?.getDataAtCell(row, column) });
      }
    },
    contextMenu: {
      callback(key, selection, clickEvent) {
        logger.log(key, selection, clickEvent);
      },
      items: {

        row_above: {
          name: 'Insert row above',
          disabled() {
            return false;
          },
        },
        row_below: {
          name: 'Insert row below',
          disabled() {
            return false;
          },
        },
        add_col_left: {
          name: 'Insert column left',
          disabled() {
            return true;
          },
          callback(key) {
            emit('insertColLeft', key);
          },
          hidden() {
            return state.hotInstance.getSelected()[1] === 0;
          },
        },
        add_col_right: {
          name: 'Insert column right',
          disabled() {
            return true;
          },
          hidden() {
            return state.hotInstance.getSelected()[1] === 0;
          },
          callback(key) {
            emit('insertColRight', key);
          },
        },
        remove_row: {
          name: 'Remove row',
          disabled() {
            return false;
          },

        },
        delete_col: {
          name: 'Remove column',
          hidden() {
            return state.hotInstance.getSelected()[1] === 0;
          },
          disabled() {
            return true;
          },
          callback(key) {
            emit('deleteCol', key);
          },

        },
        undo: {
          name: 'Undo',
          disabled() {
            return !state.hotInstance.undoRedo || !state.hotInstance.undoRedo.isUndoAvailable();
          },
        },
        redo: {
          name: 'Redo',
          disabled() {
            return !state.hotInstance.undoRedo || !state.hotInstance.undoRedo.isRedoAvailable();
          },
        },
        cut: {
          name: 'Cut',
        },
        copy: {
          name: 'Copy',
        },
        paste: {
          name: 'Paste',
        },
      },
    },
    ...(props.cells && { cells: props.cells }),
    ...(props.nestedHeaders?.length && { nestedHeaders: props.nestedHeaders }),
    afterGetRowHeader: customRowHeaderRenderer,
    ...props.hotSettings,
  };
  const container = document.getElementById(props.hotTableId);

  Handsontable.renderers.registerRenderer('phoneNumberRenderer', phoneNumberRenderer);
  Handsontable.renderers.registerRenderer('urlRenderer', urlRenderer);
  Handsontable.renderers.registerRenderer('emailRenderer', emailRenderer);
  Handsontable.renderers.registerRenderer('fileUploadRenderer', fileUploadRenderer);
  Handsontable.renderers.registerRenderer('signatureRenderer', signatureRenderer);
  Handsontable.renderers.registerRenderer('membersRenderer', membersRenderer);
  Handsontable.renderers.registerRenderer('multiSelectRenderer', multiselectRenderer);
  Handsontable.renderers.registerRenderer('triStateRenderer', triStateRenderer);
  Handsontable.renderers.registerRenderer('dateTimeRenderer', customDateRenderer);
  Handsontable.renderers.registerRenderer('multiTextRenderer', multiTextRenderer);
  Handsontable.renderers.registerRenderer('suffixPrefixRenderer', suffixPrefixRenderer);

  handleRegisterValidators();
  state.hotInstance = new Handsontable(container, hotSettings);

  state.hotInstance.validateCells();

  setTimeout(() => {
    state.hotInstance.render();
  }, 0);
  emit('ready', state.hotInstance);
  const table_element = document.getElementById(props.hotTableId);

  table_element?.addEventListener('dblclick', handleDblClick);
}

onMounted(async () => {
  await loadScripts();
  setTimeout(() => {
    initHandsontable();
  }, 1000);
});
onUnmounted(() => {
  if (state.hotInstance)
    state.hotInstance.destroy();
  const table_element = document.getElementById(props.hotTableId);
  table_element?.removeEventListener('dblclick', handleDblClick);
});

defineExpose({ getCustomFunctions });
</script>

<template>
  <div :id="hotTableId" />
</template>

<style lang="scss">
.htCore {
 font-family: "Inter var" !important;
 color: rgb(0, 0, 0) !important;
  th{
    font-weight: 500 !important;
    padding: 4px;
    border-right:  1px solid #e8e2e2 !important;
    border-bottom: 1px solid #e8e2e2 !important;
    color: rgba(71, 84, 103, 1) !important;

  }
  th:not(.ht__highlight,.summaryRow){
    background-color: rgba(249, 250, 251, 1) !important;
  }
 .ht__highlight{
   background-color: #dcdcdc !important;
 }
 .handsontable thead th.ht__active_highlight{
    background-color: #8eb0e7 !important;
  }
  thead th:last-child {
    border-right: 1px solid #e8e2e2 !important;
  }
  thead th {
    height: 34px !important;
    vertical-align: middle;
    text-align: left;
    &:nth-child(1) {
      text-align: center;
      border-right:  1px solid #e8e2e2 !important;
    }
  }
  td {
    padding: 4px;
    max-width: 30rem;
    border-right:  1px solid #e8e2e2 !important;
    border-left: none !important;
    border-bottom: 1px solid #e8e2e2 !important;

  }
  td:not(.read-only-cell, .htDisabled){
    color: rgb(71, 84, 103) !important;
  }
  td:last-child{
    border-right: 1px solid #e8e2e2 !important
  }

}
.read-only-cell{
  background-color: #f2f4f7 !important;
}
.handsontable tr:first-child td, .handsontable tr:first-child th{
    border-top: 1px solid #e8e2e2 !important;
  }
.ht_master:not(.innerBorderInlineStart):not(.emptyColumns)~.handsontable:not(.ht_clone_top) thead tr th:first-child, .ht_master:not(.innerBorderInlineStart):not(.emptyColumns)~.handsontable tbody tr th{
    border-left: 1px solid #e8e2e2 !important;
  }
  .handsontable.htRowHeaders thead tr th:nth-child(2){
    border-left: none !important;
  }
  /* Style for the 'No' checkbox with a cross */
.custom-no-state {
  position: relative;
  border-radius: 8px;
}
.custom-intermediate-state {
  position: relative;
  border-radius: 8px;
}

.custom-no-state::before {
  content: '✗';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #dc2626;

  font-size: 14px;
  width: 14px;
  height: 14px;
  line-height: 14px;

  text-align: center;
  pointer-events: none;
}
.custom-no-state:hover{
  opacity: 0.8;
}
.custom-intermediate-state:hover{
  opacity: 0.8;
}
.summaryRow{
  background-color: #dcdcdc !important;
  font-weight: 600;
}
.custom-intermediate-state::before {
  content: '-';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #aca9a9;
  font-size: 14px;

  width: 14px;
  height: 14px;

  line-height: 14px;
  text-align: center;
}
.choices__list--multiple .choices__item{
  background-color: rgb(21 112 239) !important;
}
.changeType {
  opacity: 0;
}
th:hover{
  .changeType{
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

}
.row-icon-tooltip {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  left: 50%;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  white-space: nowrap;
}

.row-icon:hover .row-icon-tooltip {
  visibility: visible;
  opacity: 1;
}
</style>
