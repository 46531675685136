import { datadogRum } from '@datadog/browser-rum';
import { includes, isArray, isEmpty, isObject, keyBy, orderBy } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCustomViewStore } from '~/common/stores/custom-view.store.js';
import { useTagsStore } from '~/common/stores/tags.store';
import { getHostName } from '~/common/utils/common.utils';

export const useCommonStore = defineStore('common', {
  persist:
  [
    {
      paths: [
        'assets_map',
        'teams_map',
        'roles_map',
        'users_map',
        'assets_custom_fields_map',
        'internal_users_map',
        'internal_users_uids_map',
      ],
      storage: localStorage,
      key: getHostName() === 'localhost' ? 'localhost_persisted_common_store' : `${import.meta.env.VITE_APP_ENV}_persisted_common_store`,
    },
    {
      paths: ['organizations_map'],
      storage: sessionStorage,
      key: getHostName() === 'localhost' ? 'localhost_persisted_common_store' : `${import.meta.env.VITE_APP_ENV}_persisted_common_store`,
    },
  ],

  // {
  //   storage: sessionStorage,
  //   key: getHostName() === 'localhost' ? 'localhost_persisted_common_store' : `${import.meta.env.VITE_APP_ENV}_persisted_common_store`,
  // },
  state: () => ({
    app_loading: false,
    reload_on_next_route_change: false,
    help_widget: false,
    asset_loading: false,
    organizations_map: null,
    categories_map: {},
    assets_map: {},
    teams_map: {},
    roles_map: {},
    users_map: {},
    quick_access_list: [],
    assets_custom_fields_map: {},
    internal_users_map: {},
    internal_users_uids_map: {},
    online_users_map: {},
    asset_meta: {},
  }),
  getters: {
    is_development() { return import.meta.env.VITE_APP_ENV === 'development'; },
    is_production() { return import.meta.env.VITE_APP_ENV === 'production'; },
    is_ril() { return import.meta.env.VITE_APP_TENANT === 'ril'; },
    organizations(state) { return Object.values(state.organizations_map || {}); },
    assets(state) {
      return Object.values(state.assets_map || {}).map((asset) => {
        const new_asset = {
          ...asset,
          metadata: keyBy(asset.metadata, 'field'),
        };
        return new_asset;
      });
    },
    is_user_online(state) { return uid => Boolean(state.online_users_map[uid]); },
    teams(state) { return Object.values(state.teams_map || {}); },
    roles(state) { return Object.values(state.roles_map || {}); },
    users(state) { return Object.values(state.users_map || {}); },
    internal_users(state) { return Object.values(state.internal_users_map || {}); },
    categories(state) { return Object.values(state.categories_map || {}); },

    get_org_or_internal_user() {
      return (uid) => {
        const user_details = this.get_user(uid);
        const auth_store = useAuthStore();
        // if user is in organization users list
        if (user_details) {
          return user_details;
        }

        // if user is internal
        else if (this.internal_users_uids_map?.[uid]) {
          // if loggedIn user is internal
          if (
            auth_store.logged_in_user_details?.user_role
            && this.internal_users_map?.[uid]
          ) {
            const user = this.internal_users_map?.[uid];
            if (user)
              return user;
          }
          else { return null; }
        }

        else {
          return null;
        }
      };
    },
    current_organization_owner() {
      return this.users.find(item => item.is_owner);
    },
    get_asset(state) { return uid => state.assets_map?.[uid]; },
    get_organization(state) { return uid => state.organizations_map?.[uid]; },
    get_category(state) { return uid => state.categories_map?.[uid]; },
    get_user(state) { return uid => state.users_map?.[uid]; },
    get_team(state) { return uid => state.teams_map?.[uid]; },
    get_role(state) { return uid => state.roles_map?.[uid]; },
    assets_custom_fields(state) { return Object.values(state.assets_custom_fields_map || {}); },
    active_asset(state) {
      return state.assets_map?.[this.$router?.currentRoute?.value?.params?.asset_id];
    },
    get_asset_or_org_name(state) {
      const auth_store = useAuthStore();
      return (uid) => {
        return uid
          ? (state.get_asset(uid)?.name || '')
          : (auth_store.current_organization?.name || '');
      };
    },
    get_user_or_team_name(state) {
      return (uid) => {
        const auth_store = useAuthStore();
        if (uid === auth_store?.logged_in_user_details?.user_id) {
          const user = auth_store?.logged_in_user_details;
          return user.firstname ? `${user.firstname} ${user.lastname || ''}` : (user.username || user.email);
        }
        else if (state?.users_map?.[uid]) {
          const user = state?.users_map?.[uid];
          return user.first_name ? `${user.first_name} ${user.last_name || ''}` : user.username;
        }
        else if (state?.teams_map?.[uid]) {
          return state.teams_map[uid].name;
        }
        return '';
      };
    },
    members_scope_users(state) {
      return (asset_id, include_team_members = true) => {
        if (!asset_id)
          return this.users.filter(user => !user.asset);

        if (!state?.assets_map?.[asset_id])
          return [];

        const team_members = include_team_members ? (state?.assets_map?.[asset_id]?.team_members.map(user => user.uid) || []) : [];
        const members = state?.assets_map?.[asset_id]?.members || [];

        const data = ([...members, ...team_members])?.reduce((arr, uid) => {
          if (state.users_map[uid])
            arr.push(state.users_map[uid]);
          return arr;
        }, []);
        return orderBy(data, ['first_name', 'email']);
      };
    },
    scope_users(state) {
      return (asset_id, search) => {
        let data = [];
        if (!asset_id) {
          data = this.users.filter(user => !user.asset && user.user_type !== 'guest');
        }
        else {
          const team_members = state?.assets_map?.[asset_id]?.team_members.map(user => user.uid) || [];
          const members = state?.assets_map?.[asset_id]?.members || [];
          data = ([...members, ...team_members])?.reduce((arr, uid) => {
            if (state.users_map?.[uid])
              arr.push(state.users_map[uid]);
            return arr;
          }, []);
        }

        if (search) {
          search = search.toLowerCase();
          data = data.filter(item =>
            includes(
              (item.first_name
                ? item.first_name + item.last_name + item.email
                : item.email
              ).toLowerCase(),
              search,
            ),
          );
        }
        return orderBy(data, ['first_name', 'email']);
      };
    },
    scope_teams(state) {
      return (asset_id, search) => {
        let data = [];

        if (!asset_id) {
          data = this.teams.filter(item => !item.asset);
        }

        else {
          data = (state.assets_map[asset_id]?.teams || [])?.reduce((arr, uid) => {
            if (state?.teams_map?.[uid])
              arr.push(state.teams_map[uid]);
            return arr;
          }, []);
        }

        if (search) {
          search = search.toLowerCase();
          data = data.filter(item => includes(item.name.toLowerCase(), search));
        }
        return orderBy(data, ['name']);
      };
    },
    scope_roles(state) {
      return (asset_id) => {
        if (!asset_id)
          return this.roles.filter(role => !role.asset);

        return (state.assets_map[asset_id]?.roles || [])?.reduce((arr, uid) => {
          if (state.roles_map?.[uid])
            arr.push(state.roles_map[uid]);
          return arr;
        }, []);
      };
    },
    scope_categories() {
      return (asset_id, type) => {
        return this.categories.filter((item) => {
          if (type === 'list')
            return asset_id ? item.asset === asset_id : item.asset === null;
          else return item.asset === asset_id || item.asset === null;
        });
      };
    },
    get_asset_meta(state) {
      return (asset_id, property) => {
        if (state.asset_meta?.[asset_id])
          return property ? state.asset_meta[asset_id]?.[property] : state.asset_meta[asset_id];
        return null;
      };
    },
  },
  actions: {
    async set_form_quick_access(options = {}) {
      const response = await this.$services.forms.getAll({
        attribute: 'templates/quick-access',
        query: {
          asset_uid: this.$router?.currentRoute?.value?.params?.asset_id,
          device: 'desktop',
        },
        headers: options.headers || {},
      });
      this.quick_access_list = response.data.form;
    },

    add_custom_data_to_marker_io() {
      try {
        const auth_store = useAuthStore();
        const rum_session_id = window.DD_RUM?.getInternalContext?.()?.session_id;

        const custom_data = {
          organization_id: auth_store.current_organization?.uid,
          organization_name: auth_store.current_organization?.name,
        };

        if (this.active_asset) {
          custom_data.asset_id = this.active_asset.uid;
          custom_data.asset_name = this.active_asset.name;
        }

        if (rum_session_id)
          custom_data.session_replay = `https://app.datadoghq.com/rum/replay/sessions/${rum_session_id}?from=${Number(new Date())}`;

        window.Marker.setCustomData(custom_data);
      }
      catch (error) {
        logger.log(error);
        logger.warn('Something went wrong while adding marker.io custom data');
      }
    },
    get_user_details_payload(user, current_organization) {
      const auth_store = useAuthStore();
      const user_payload = {
        id: user.user_id,
        org_name: current_organization?.name,
        org_id: current_organization?.uid,
        name: `${user?.firstname ?? ''} ${user?.lastname ?? ''}`,
        email: user.email,
        type: auth_store.is_internal_user ? 'Internal' : 'Customer',
      };
      return user_payload;
    },
    segment_analytics_identify() {
      try {
        const auth_store = useAuthStore();
        window.analytics.identify(auth_store.logged_in_user_details.user_id, {
          is_online: this.is_user_online(auth_store.logged_in_user_details.user_id),
          organization: auth_store.current_organization.name,
          organization_id: auth_store.current_organization.uid,
          access_level: auth_store.get_access_level,
          email: auth_store.is_internal_user ? auth_store.logged_in_user_details?.email : null,
          name: auth_store.is_internal_user ? `${auth_store.logged_in_user_details?.firstname ?? ''} ${auth_store.logged_in_user_details?.lastname ?? ''}` : 'Not tracked',
          user_type: auth_store.is_internal_user ? 'Internal' : 'Customer',
        });
      }
      catch (error) {
        logger.log(error);
        logger.warn('Something went wrong while adding segment custom data');
      }
    },
    segment_analytics_group() {
      try {
        const auth_store = useAuthStore();
        window.analytics.group(auth_store.logged_in_user_details.user_id, {
          organization: auth_store.current_organization.name,
          organization_id: auth_store.current_organization.uid,
        });
      }
      catch (error) {
        logger.log(error);
        logger.warn('Something went wrong while adding segment group data');
      }
    },
    datadog_identify() {
      try {
        const auth_store = useAuthStore();
        const user = auth_store.logged_in_user_details;

        if (!auth_store.is_datadog_initialized || !user)
          return;

        const user_details_payload = this.get_user_details_payload(user, auth_store.current_organization);

        if (!auth_store.is_internal_user) {
          user_details_payload.email = null;
          user_details_payload.name = 'Not tracked';
        }

        datadogRum.setUser(user_details_payload);
      }
      catch (error) {
        logger.log(error);
        logger.warn('Something went wrong while adding datadog custom data');
      }
    },
    async set_global_data() {
      const auth_store = useAuthStore();
      const tags_store = useTagsStore();
      const custom_view_store = useCustomViewStore('asset_list');

      this.asset_loading = true;
      const { data: asset_data } = await this.$services.common.post({
        url: 'initial-resources',
        body: {
          assets: true,
          asset_custom_fields: true,
          views: true,
        },
        query: {
          display_picture: true,
          asset: false,
        },
      });
      this.assets_custom_fields_map = keyBy(asset_data.asset_custom_fields?.fields || asset_data.asset_custom_fields, 'uid');
      this.assets_map = keyBy(asset_data.assets, 'uid');
      custom_view_store.set_custom_views(null, { views: asset_data.views ? [asset_data.views] : [] });
      this.asset_loading = false;

      const { data } = await this.$services.common.post({
        url: 'initial-resources',
        body: {
          users: true,
          teams: true,
          roles: true,
          categories: true,
          tags: true,
          internal_users: true,
          internal_users_uids: true,
        },
        query: {
          display_picture: true,
          asset: false,
        },
      });
      if (!this.organizations?.length)
        this.set_organization_list();

      this.internal_users_uids_map = keyBy(data.internal_users_uids);
      this.internal_users_map = keyBy(data.internal_users, 'uid');
      this.categories_map = keyBy(data.categories, 'uid');
      this.roles_map = keyBy(data.roles, 'uid');
      this.teams_map = keyBy(data.teams, 'uid');
      this.users_map = keyBy(data.users, 'uid');
      auth_store.set_signature();
      auth_store.set_ip_address();
      tags_store.tags_map = keyBy(data.tags, 'uid');
    },
    async update_global_data(options = {}) {
      const { data } = await this.$services.common.post({
        url: 'initial-resources',
        body: {
          assets: options.assets,
          users: options.users,
          teams: options.teams,
          roles: options.roles,
          categories: options.categories,
          tags: options.tags,
          internal_users: options.internal_users,
          internal_users_uids: options.internal_users_uids,
          asset_custom_fields: options.asset_custom_fields,
        },
        query: options.users ? { display_picture: true, asset: false } : { asset: false },
      });

      data.assets && (this.assets_map = { ...this.assets_map, ...keyBy(data.assets, 'uid') });
      data.users && (this.users_map = { ...this.users_map, ...keyBy(data.users, 'uid') });
      data.teams && (this.teams_map = { ...this.teams_map, ...keyBy(data.teams, 'uid') });
      data.roles && (this.roles_map = { ...this.roles_map, ...keyBy(data.roles, 'uid') });
      data.categories && (this.categories_map = { ...this.categories_map, ...keyBy(data.categories, 'uid') });
      data.internal_users && (this.internal_users_map = { ...this.internal_users_map, ...keyBy(data.internal_users, 'uid') });
      data.internal_users_uid && (this.internal_users_uids_map = { ...this.internal_users_uids_map, ...keyBy(data.internal_users_uids) });
      data.asset_custom_fields && (this.assets_custom_fields_map = { ...this.assets_custom_fields_map, ...keyBy(data.asset_custom_fields?.fields || data.asset_custom_fields, 'uid') });

      const tags_store = options.tags && useTagsStore();
      data.tags && (tags_store.tags_map = { ...tags_store.tags_map, ...keyBy(data.tags, 'uid') });
    },
    remove_global_data(options) {
      if (!this[options.state_prop])
        return;
      options.items.forEach((item) => {
        if (this[options.state_prop][item?.uid || item])
          delete this[options.state_prop][item?.uid || item];
      });
      this[options.state_prop] = { ...this[options.state_prop] };
    },
    setup_analytics() {
      this.segment_analytics_identify();
      this.segment_analytics_group();
      this.datadog_identify();
      this.add_custom_data_to_marker_io();
    },
    async initialize(options) {
      const auth_store = useAuthStore();
      this.app_loading = isEmpty(this.users_map); // this should probably just be `true`
      // Reset asset metadata when app loads to avoid using cached data
      this.asset_meta = {};

      try {
        await auth_store.set_logged_in_user_details(options);

        this.app_loading = false;
        this.set_global_data(options);
        this.setup_analytics();
      }
      catch (err) {
        this.app_loading = false;

        logger.info(`[DEBUG] common.store.js::414\n${err}`);
        if (this.$router.currentRoute.value.name !== 'something-went-wrong') {
          this.$router.replace({
            name: 'something-went-wrong',
            query: {
              from: this.$router.currentRoute.value.fullPath,
            },
          });
        }
      }
    },
    async  toggle_chat_with_us(toggle) {
      if (window.Atlas) {
        setTimeout(() => {
          this.toggle_chat(toggle);
        }, 500);
      }
    },

    async chat_widget_user_identification() {
      const auth_store = useAuthStore();
      try {
        if (auth_store.logged_in_user_details?.user_id) {
          window.Atlas.call(
            'identify',
            {
              userId: auth_store.logged_in_user_details?.email,
              name: `${auth_store.logged_in_user_details?.firstname || ''} ${auth_store.logged_in_user_details?.lastname || ''}`,
              email: auth_store.logged_in_user_details?.email,
              phoneNumber: auth_store.logged_in_user_details?.phone_number || '-',
            },
          );
        }
      }
      catch (err) {
        logger.error(err);
      }
    },

    async set_organization_list(options) {
      if (!this.organizations.length || options?.forceUpdate) {
        const { data } = await this.$services.common.get({
          url: 'organization-list',
          query: {
            limit: Number.MAX_SAFE_INTEGER,
            page: 1,
          },
        });
        this.organizations_map = keyBy(data.organizations, 'uid');
      }
    },

    get_target_element(asset_id = null, element = null) {
      const auth_store = useAuthStore();
      if (element?.uid)
        return element;
      const asset_uid = asset_id || this.$router?.currentRoute?.value?.params?.asset_id;
      if (asset_uid)
        return this.assets_map[asset_uid].element;
      return auth_store?.current_organization?.element;
    },
    async update_data(options = {}) {
      try {
        const state_data = this[options.state_prop];
        let response = null;
        if (!options.service)
          return;
        switch (options.type) {
          case 'add':
            response = await this.$services[options.service].post({
              headers: options.headers || {},
              query: options.query || {},
              body: options.data,
              ...(options.url && { url: options.url }),
            });

            if (options.update_state) {
              if (options.append_data) {
                if (Array.isArray(this[options.state_prop]))
                  this[options.state_prop].push(options.response_key ? response.data[options.response_key] : response.data);

                else this[options.state_prop] = options.response_key ? { ...(Array.isArray(response.data[options.response_key]) ? response.data[options.response_key] : [response.data[options.response_key]]), ...this[options.state_prop] } : response.data;
              }

              else {
                this[options.state_prop] = options.response_key ? response.data[options.response_key] : response.data;
              }
            }

            break;
          case 'update':
            response = await this.$services[options.service].put({
              asset_id: options?.asset_id,
              headers: options.headers || {},
              id: options.id,
              body: options.data,
              query: options.query || {},
              attribute: options.attribute || '',
              ...(options.url && { url: options.url }),
            });
            if (options.update_state) {
              if (Array.isArray(this[options.state_prop])) {
                const index = this[options.state_prop].findIndex(item => item.uid === options.id);
                this[options.state_prop][index] = options.response_key ? response.data[options.response_key] : response.data;
              }
              else { this[options.state_prop][options.id] = options.response_key ? response.data[options.response_key] : response.data; }
            }
            break;
          case 'patch_update':
            response = await this.$services[options.service].patch({
              asset_id: options?.asset_id,
              headers: options.headers || {},
              id: options.id,
              body: options.data,
              query: options.query || {},
              ...(options.url && { url: options.url }),
            });
            if (options.update_state) {
              if (Array.isArray(this[options.state_prop])) {
                const index = this[options.state_prop].findIndex(item => item.uid === options.id);
                this[options.state_prop][index] = options.response_key ? response.data[options.response_key] : response.data;
              }
              else { this[options.state_prop][options.id] = options.response_key ? response.data[options.response_key] : response.data; }
            }
            break;
          case 'delete':
            response = await this.$services[options.service].delete({
              headers: options.headers || {},
              id: options.id,
              body: options.data,
              query: options.query || {},
              ...(options.url && { url: options.url }),
            });
            if (Array.isArray(this[options.state_prop])) {
              const index = this[options.state_prop].findIndex(item => item.uid === options.id);
              this[options.state_prop].splice(index, 1);
            }
            else {
              delete state_data[options.id];
              this[options.state_prop] = state_data;
            }
            break;
          default:
            logger.error('Invalid operation type');
        }
        return response;
      }
      catch (err) {
        logger.error(err);
        throw err;
      }
    },
    async set_terra_therm_list_in_assets(
      { asset_id, data, key },
    ) {
      if (this.assets_map[asset_id]) {
        const assets = this.assets_map;
        assets[asset_id] = { ...assets[asset_id], [key]: data };
        this.assets_map = assets;
      }
    },
    is_type_team(item) {
      return !!this.teams_map?.[isObject(item) ? item.uid : item];
    },
    is_type_user(item) {
      return !!this.users_map?.[isObject(item) ? item.uid : item];
    },
    filter_users(users) {
      // supports
      // ['uid1','uid2','uid3','uid4']
      // [{uid:'uid1'},{uid:'uid2'},{uid:'uid3'},{uid:'uid4'}]
      // 'uid1'
      if (isArray(users)) {
        return users?.reduce((user_list, item) => {
          if (this.is_type_user(item))
            user_list.push(item);

          return user_list;
        }, []);
      }
      else {
        return this.users_map[users];
      }
    },
    filter_teams(teams) {
      if (isArray(teams)) {
        return teams?.reduce((team_list, item) => {
          if (this.is_type_team(item))
            team_list.push(item);

          return team_list;
        }, []);
      }
      else {
        return this.teams_map[teams];
      }
    },
    async set_asset_instances(asset_id) {
      try {
        const { data } = await this.$services.sm_instances.get_asset_instances({ asset_id });
        if (data?.instances) {
          if (this.asset_meta[asset_id]) {
            this.asset_meta[asset_id] = {
              ...this.asset_meta[asset_id],
              instances: data.instances,
            };
          }
          else {
            this.asset_meta[asset_id] = {
              instances: data.instances,
            };
          }
        }
      }
      catch (error) {
        logger.error(error);
      }
    },
    toggle_chat(value) {
      // Not maintaining states as this is persisted and will cause issues when toggling after reload
      if (value) {
        window.Atlas.chat.showBubble();
        window.Atlas.chat.openWindow();
      }
      else {
        window.Atlas.chat.hideBubble();
        window.Atlas.chat.closeWindow();
      }
    },
  },

});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCommonStore, import.meta.hot));
