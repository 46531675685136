<script setup>
import RichTextIcon from '~icons/hawk/basic-section';
import CheckboxIcon from '~icons/hawk/checklist-section';
import TableIcon from '~icons/hawk/table-section';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  section: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emits = defineEmits(['select_section']);

const form_template_detail_store = inject('form_template_detail_store');
const section = props.section;

const {
  $services,
  $t,
  $toast,
  route,
} = useCommonImports();

const search = ref('');

async function createSection({ section, index, type }) {
  try {
    const obj = {
      name: '',
      form: form_template_detail_store.form_template_detail.uid,
      type,
      status: 'active',
      previous_section: section?.uid,
      step_index: form_template_detail_store.step_number !== -1 ? form_template_detail_store.step_number : 1,
    };

    form_template_detail_store.form_template_detail.sections.splice(
      index + 1,
      0,
      obj,
    );
    emits('select_section', { section: obj, index });

    return obj;
  }
  catch (e) {
    logger.log(e);
    form_template_detail_store.reset_form_template();
  }
}

const section_types = [
  { label: $t('Basic'), icon: RichTextIcon, on_click: () => createSection({ type: 'default', section: section.element, index: section.index }) },
  { label: $t('Checklist'), icon: CheckboxIcon, on_click: () => createSection({ type: 'checklistv2', section: section.element, index: section.index }) },
  { label: $t('Table'), icon: TableIcon, on_click: () => createSection({ type: 'tablev2', section: section.element, index: section.index }) },
];

const searched_section_templates = computed(() => search.value ? form_template_detail_store.section_templates.filter(template => template.label.toLowerCase().includes(search.value.toLowerCase())) : form_template_detail_store.section_templates);

const section_templates = computed(() => {
  const icon_map = {
    default: RichTextIcon,
    checklistv2: CheckboxIcon,
    tablev2: TableIcon,
  };

  return searched_section_templates.value.map((section_template, index) => {
    if (index === 0) {
      section_template.menu_item_header = 'Templates';
      section_template.additional_item_classes = 'border-t border-gray-200';
    }

    section_template.icon = icon_map[section_template.type];
    section_template.label = section_template.name;

    return section_template;
  });
});

const section_menu_items = computed(() => {
  return [
    ...section_types,
    ...section_templates.value,
  ];
},
);

async function loadTemplateSectionFields(template) {
  try {
    const payload = {
      section: {
        name: template.name,
        form: route.params.template_uid,
        uid: template.section_template_uid,
        sectionUid: template.uid,
      },
    };

    await $services.forms.post({
      body: payload,
      attribute: 'sections/template/load',
    });

    $toast({
      title: $t('Template loaded'),
      text: $t('Successfully added fields from the template.'),
      type: 'success',
    });
  }
  catch (err) {
    logger.log(err);
    $toast({
      title: $t('Failed to load template'),
      text: $t('Unable to load fields from the template. Please try again.'),
      type: 'error',
    });
  }
}

async function onTemplateSelect(selected_template) {
  if (!selected_template.is_template)
    return;

  form_template_detail_store.is_loading_section_templates = true;

  try {
    const section_payload = {
      name: selected_template.name,
      form: form_template_detail_store.form_template_detail.uid,
      type: selected_template.type,
      status: 'active',
      previous_section: section.element?.uid,
      step_index: form_template_detail_store.step_number !== -1 ? form_template_detail_store.step_number : 1,
    };

    form_template_detail_store.form_template_detail.sections.splice(
      section.index + 1,
      0,
      section_payload,
    );

    const { data } = await $services.forms.post({
      attribute: 'sections',
      body: {
        section: section_payload,
      },
    });

    await loadTemplateSectionFields({ ...data.section, section_template_uid: selected_template.uid });
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
  }
  catch (error) {
    logger.log(error);
    form_template_detail_store.reset_form_template();

    $toast({
      title: $t('Failed to load template'),
      text: error.data.message,
      type: 'error',
    });
  }

  form_template_detail_store.is_loading_section_templates = false;
}
</script>

<template>
  <hawk-menu
    class="my-4"
    position="bottom-right"
    additional_trigger_classes="!ring-0 !border-0"
    additional_header_classes="border-0 sticky top-0 z-999 bg-white"
    additional_dropdown_classes="w-[15rem] max-h-[27rem] scrollbar !z-[19]"
    :items="section_menu_items"
    @click.stop
    @select="onTemplateSelect"
  >
    <template #trigger>
      <hawk-button type="plain">
        {{ $t('New section') }}
        <icon-hawk-chevron-down class="text-blue-600" />
      </hawk-button>
    </template>
    <template v-if="form_template_detail_store.section_templates.length > 5" #header>
      <HawkSearchInput
        v-model="search" :placeholder="$t('Search for templates')"
        full_width
        @keydown.stop
      />
    </template>
    <template #item="{ item, active }">
      <div class="mr-2">
        <component :is="item.icon" :class="{ 'text-gray-500': !active }" />
      </div>
      <HawkText :content="item.label" length="28" />
    </template>
    <template v-if="search && !searched_section_templates.length" #footer>
      <div class="text-gray-600 px-4 py-2">
        {{ $t('No template found') }}
      </div>
    </template>
  </hawk-menu>
</template>
