import { acceptHMRUpdate, defineStore } from 'pinia';
import { accessTokenCookie, handleAppUpdate } from '~/common/utils/common.utils';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';

export function usePusherStore(key) {
  return defineStore(key || 'pusher', {
    state: () => ({
      is_initialized: false,
      PUSHER: null,
      channel: null,
      assets: [],
    }),
    getters: {

    },
    actions: {
      async initialize(params = {}) {
        const { default: Pusher } = await import('pusher-js');
        try {
          const token = accessTokenCookie();
          this.PUSHER = new Pusher(import.meta.env.VITE_APP_PUSHER_KEY, {
            cluster: 'ap2',
            forceTLS: true,
            channelAuthorization: {
              endpoint: `${import.meta.env.VITE_APP_SENSEHAWK_HOST}/pusher/auth/`,
              headers: {
                Authorization: `jwt ${token}`,
              },
              params,
            },
          });
          // To enable logs, uncomment Pusher.logToConsole = true

          if (localStorage.getItem('SH_PUSHER_DEBUG')) {
            // eslint-disable-next-line no-console
            console.info(`Pusher initialized as [${import.meta.env.VITE_APP_PUSHER_KEY.slice(0, 5)}]`);
            Pusher.logToConsole = true;
          }

          this.set_global_events();
          this.is_initialized = true;
        }
        catch (e) {
          console.error(e);
          logger.error(e);
        }
      },
      set_global_events() {
        this.set_app_users_events();
        this.set_app_teams_events();
        this.set_app_assets_events();
        this.set_app_deployment_events();
      },
      set_app_users_events() {
        const auth_store = useAuthStore();
        const common_store = useCommonStore();

        const users_channel = this.PUSHER.subscribe(
          `private-users-org_${auth_store.current_organization?.uid}`,
        );
        users_channel.bind('USERS_ADDED', (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.update_global_data({ users: data?.users || true });
        });
        users_channel.bind('USERS_UPDATED', (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.update_global_data({ users: data?.users || true });
        });
        users_channel.bind('USERS_REMOVED', (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.remove_global_data({ state_prop: 'users_map', items: data.users });
        });
      },
      set_app_teams_events() {
        const auth_store = useAuthStore();
        const common_store = useCommonStore();

        const teams_channel = this.PUSHER.subscribe(
          `private-teams-org_${auth_store.current_organization?.uid}`,
        );
        teams_channel.bind('TEAMS_ADDED', (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.update_global_data({ teams: data?.teams || true });
        });
        teams_channel.bind('TEAMS_UPDATED', (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.update_global_data({ teams: data?.teams || true });
        });
        teams_channel.bind('TEAMS_REMOVED', (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.remove_global_data({ state_prop: 'teams_map', items: data.teams });
        });
      },
      set_app_assets_events() {
        const auth_store = useAuthStore();
        const common_store = useCommonStore();
        const asset_channel = this.PUSHER.subscribe(
          `private-assets-org_${auth_store.current_organization?.uid}`,
        );
        asset_channel.bind('ASSETS_ADDED', async (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.update_global_data({ assets: data?.assets || true });
        });
        asset_channel.bind('ASSETS_UPDATED', async (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.update_global_data({ assets: data?.assets || true });
        });
        asset_channel.bind('ASSETS_REMOVED', async (data) => {
          if (auth_store.logged_in_user_id !== data.modified_by)
            common_store.remove_global_data({ state_prop: 'assets_map', items: data.assets });
        });
      },
      set_app_deployment_events() {
        const deployment_channel = this.PUSHER.subscribe('new_deployment');
        deployment_channel.bind('NEW_REVAMP_DEPLOYMENT', () => {
          handleAppUpdate('NEW_REVAMP_DEPLOYMENT');
        });
      },
    },
  })();
}

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePusherStore, import.meta.hot));
