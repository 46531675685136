<!-- eslint-disable vue/prop-name-casing -->
<script setup>
const props = defineProps({
  modelValue: {
    type: Number,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  specific_values: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const checkbox_icon_map = {
  '0': IconCheckboxStateOnHold,
  '-1': IconCheckboxStateRejected,
  '1': IconCheckboxStateResolved,
  'null': IconCheckboxStateNull,
  'undefined': IconCheckboxStateNull,
};

const state_change_map = {
  '0': null,
  '1': -1,
  '-1': 0,
  'null': 1,
  'undefined': 1,
};

function updateState() {
  emit('update:modelValue', state_change_map[`${props.modelValue}`]);
}

function updateStateOne() {
  const { specific_values, modelValue } = props;
  const find_index = specific_values.indexOf(modelValue);
  const cb_value = specific_values[(find_index + 1) % specific_values.length];
  emit('update:modelValue', cb_value);
}
</script>

<template>
  <span
    v-if="specific_values?.length"
    class="cursor-pointer"
    :class="{ 'pointer-events-none cursor-not-allowed': disabled }"
    @click="updateStateOne"
  >
    <component :is="checkbox_icon_map[`${modelValue}`]" :key="modelValue" />
  </span>
  <span v-else class="cursor-pointer" :class="{ 'pointer-events-none cursor-not-allowed': disabled }" @click="updateState">
    <IconCheckboxStateOnHold v-if="modelValue === 0" />
    <IconCheckboxStateRejected v-else-if="modelValue === -1" />
    <IconCheckboxStateResolved v-else-if="modelValue === 1" />
    <IconCheckboxStateNull v-else />
  </span>
</template>
